// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-chroma-tsx": () => import("./../../../src/pages/projects/chroma.tsx" /* webpackChunkName: "component---src-pages-projects-chroma-tsx" */),
  "component---src-pages-projects-infinity-piano-jsx": () => import("./../../../src/pages/projects/infinity-piano.jsx" /* webpackChunkName: "component---src-pages-projects-infinity-piano-jsx" */),
  "component---src-pages-projects-neural-melody-tsx": () => import("./../../../src/pages/projects/neural-melody.tsx" /* webpackChunkName: "component---src-pages-projects-neural-melody-tsx" */),
  "component---src-pages-projects-stock-portfolio-tsx": () => import("./../../../src/pages/projects/stock-portfolio.tsx" /* webpackChunkName: "component---src-pages-projects-stock-portfolio-tsx" */),
  "component---src-pages-projects-tokentax-tsx": () => import("./../../../src/pages/projects/tokentax.tsx" /* webpackChunkName: "component---src-pages-projects-tokentax-tsx" */)
}

